.dialog {
  white-space: pre-line;
  
  .MuiDialog-paper {
    border-radius: 1em;
  }

  .MuiButton-outlined {
    margin-left: 1em;
    border-color: #e8e8e8;
    text-transform: none;
    color: black;
    white-space: nowrap;
    height: 2.5em;
    border-radius: 0.5em;
    &:hover {
      border-color: #e8e8e8;
      background-color: white;
      box-shadow: 0 0.25em 0 rgba(0, 0, 0, 0.08);
    }
  }

  .MuiButton-contained {
    vertical-align: middle;
    border-radius: 0.8em;
    text-transform: none;
    padding-left: 0.7em;
    padding-right: 0.7em;

    &.MuiButton-containedPrimary {
      background-color: #ff8743;
      color: white;
      &:hover {
        box-shadow: 0 0.25em 0 rgba(255, 135, 67, 0.32);
      }
      &.Mui-disabled {
        background-color: #ff874352;
        color: white;
      }
    }

    &.MuiButton-containedSecondary {
      background-color: #ebebeb;
      color: black;
      &:hover {
        box-shadow: 0 0.25em 0 rgba(0, 0, 0, 0.16);
      }
      &.Mui-disabled {
        background-color: #f5f5f5;
        color: #c2c2c2;
      }
    }

    &.MuiButton-containedInfo {
      background-color: #ebebeb;
      color: black;
      align-items: center;
      width: 100%;
      margin: 0 2em !important;
    }

    &.MuiButton-containedError {
      background-color: #f02626;
      &:hover {
        box-shadow: 0 0.25em 0 rgba(255, 83, 67, 0.32);
      }
    }
  }
  .MuiButton-contained.coupon-ok-btn {
    width: 19em;
    margin: 0em 2em !important;
  }

  .dialog-actions {
    justify-content: center;
    margin-bottom: 1em;
  }

  .coupon-dialog-actions {
    justify-content: space-between;
    padding-top: 0em;
    margin-bottom: 0.75em;
  }

  .title {
    margin-top: 2em;
    text-align: center;
  }

  .coupon-dialog-title {
    font-weight: 700;
    margin-bottom: 0.5em;
  }

  .close {
    position: absolute;
    right: 1em;
    top: 1em;
    color: #858585;
    cursor: pointer;
    margin-bottom: 1em;
  }

  p.centered-text {
    align-self: center;
  }
}

.MuiTypography-root.MuiTypography-root {
  &.info {
    color: #858585 !important;
  }
}
