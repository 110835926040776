.date-picker {
  border: none;
  background-color: #f9f9f9 !important;
  cursor: pointer;
  height: 2.3em;
  margin-left: 0.5em;
  font-family: 'Jost';
  font-size: 16px;
  width: 13.5em;
  outline: none;
}

.date-picker-box {
  display: flex;
  flex-direction: row;
  background-color: #f9f9f9 !important;
  border: 1px solid #ebebeb;
  border-radius: 0.5em;
  width: 100%;
  cursor: pointer;
  height: 2.5em;
  align-items: center;
}

.picker-icon {
  margin-right: 0.5em;
}

.copy-icon {
  cursor: pointer;
}

.button-box {
  margin-bottom: 1.25em;
}

.MuiBox-root.coupon-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

::placeholder {
  color: #adadad;
}

.MuiBox-root.code-box-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.MuiBox-root {
  .code-box {
    background-color: #f5f5f5;
    border-radius: 0.5em;
    width: 33em;
    min-height: 9em;
    margin-top: 0.5em;
    margin-bottom: 2.5em;
  }
}

.MuiBox-root.code-box-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.MuiDivider-root.code-box-divider {
  margin-top: 1em;
  margin-bottom: -0.75em;
}

.coupon-dialog-msg {
  text-align: center;
  margin-top: 2.5em;
  height: 2em;
}

.delete-coupon-dialog-msg {
  text-align: center;
  font-family: 'Jost';
  font-size: 16px;
  color: #858585;
  margin: 0 1em;
}

.MuiTypography-root.delete-coupon-highlight {
  color: black;
  display: inline;
  font-weight: 600;
}

.MuiTypography-root.offer-id-title {
  color: rgba(0, 0, 0, 0.47) !important ;
  margin-left: 0.5em;
  margin-right: 0.25em;
  font-weight: 415;
}

.MuiTypography-root.offer-id-value {
  font-weight: 415;
}

.MuiBox-root.icon-row {
  display: flex;
  flex-direction: row;
}

.MuiBox-root.add-coupon-box {
  display: flex;
  flex-direction: column;
}

.MuiBox-root.add-coupon-header {
  display: flex;
  flex-direction: row;
  margin-top: 0.5em;
  gap: 9.5em;
}

.MuiBox-root.coupon-input-box {
  display: flex;
  flex-direction: row;
  gap: 0.6em;
  flex-basis: 5em;
  align-items: center;
  margin-top: -0.75em;
  justify-content: space-between;
}

.MuiButtonBase-root.delete-icon {
  margin-left: -0.35em;
}

.hidden-icon {
  visibility: hidden;
}

.MuiDivider-root.coupons-divider {
  margin-bottom: 1em;
}

.repeatable-input {
  width: 15em;
}

.coupon-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}