.reorder-dialog {
  .MuiDialog-paper {
    border-radius: 1em;
    width: 35em;
    max-height: 50em;
    padding: 0 1em 0 1em;
  }

  .reorder-title {
    font-weight: 700;
    padding-left: 0;
  }

  .close-icon {
    position: absolute;
    right: 1em;
    top: 1.5em;
    color: #858585;
    cursor: pointer;
    margin-right: 1em;
  }

  .drag-drop-box {
    background-color: #f5f5f5;
    margin-bottom: 2em;
    border-radius: 0.5em;
  }

  .dragging-box {
    border-radius: 0.5em;
    background-image: linear-gradient(to right, #adadad 50%, transparent 50%),
      linear-gradient(to right, #adadad 50%, transparent 50%),
      linear-gradient(to bottom, #adadad 50%, transparent 50%),
      linear-gradient(to bottom, #adadad 50%, transparent 50%);
    background-position: left top, left bottom, left top, right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 17px 1px, 17px 1px, 1px 17px, 1px 17px;
  }

  .drag-icon {
    padding: 0.5em 0.5em 0.5em 1em;
    border-right: 1px solid #e6e6e6;
  }

  .ui-position {
    padding: 0.1em 0.5em 0 1.5em;
    align-self: center;
  }

  .category-name {
    flex-grow: 2;
    padding-left: 1em;
    align-self: center;
  }

  .MuiButton-outlined {
    margin-left: 1em;
    border-color: #e8e8e8;
    text-transform: none;
    color: black;
    white-space: nowrap;
    height: 2.5em;
    border-radius: 0.5em;
    &:hover {
      border-color: #e8e8e8;
      background-color: white;
      box-shadow: 0 0.25em 0 rgba(0, 0, 0, 0.08);
    }
  }

  .MuiButton-contained {
    vertical-align: middle;
    border-radius: 0.8em;
    text-transform: none;
    padding-left: 0.7em;
    padding-right: 0.7em;

    &.MuiButton-containedPrimary {
      background-color: #ff8743;
      color: white;
      &:hover {
        box-shadow: 0 0.25em 0 rgba(255, 135, 67, 0.32);
      }
      &.Mui-disabled {
        background-color: #ff874352;
        color: white;
      }
    }

    &.MuiButton-containedSecondary {
      background-color: #ebebeb;
      color: black;
      &:hover {
        box-shadow: 0 0.25em 0 rgba(0, 0, 0, 0.16);
      }
      &.Mui-disabled {
        background-color: #f5f5f5;
        color: #c2c2c2;
      }
    }
  }
}
.offer-info-box {
  font-size: 12px;
  color: grey;
  .offer-id {
    float: left;
    margin-top: 1px;
    margin-right: 2px;
  }
  .offer-status-title {
    margin-left: 2px;
    vertical-align: middle;
    &.warning {
      color: #f0ab26;
    }

    &.success {
      color: #34b677;
    }

    &.danger {
      color: #f00;
    }
  }
}
.campaign-info-box {
  font-size: 12px;
  color: grey;
  .campaign-id {
    float: left;
    margin-top: 1px;
    margin-right: 2px;
  }
  .campaign-status-title {
    margin-left: 2px;
    vertical-align: middle;
    &.warning {
      color: #f0ab26;
    }

    &.success {
      color: #34b677;
    }

    &.danger {
      color: #f00;
    }
  }
}
