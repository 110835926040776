.select-component {
  .MuiFormLabel-root {
    margin-left: .4em;
    margin-top: .8em;
  }

  &.MuiAutocomplete-input,
  .MuiAutocomplete-inputRoot {
    padding-left: 0 !important;
  }
  
  input {
    caret-color: transparent;
  }
  
  .MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
    margin-top: 0;
    height: 2em;
    padding-left: 1em;
  }

  .MuiAutocomplete-popupIndicator {
    &:hover {
      background-color: transparent;
    }
  }
  
  .MuiOutlinedInput-root {
    padding-right: 0 !important;
  }

  .MuiButton-outlined {
    height: 3.3em;
    margin-top: .1em;
  }
}

.MuiPaper-root.MuiPopover-paper {
  .MuiList-root {
    max-height: 15em;
  }
}

