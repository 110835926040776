@import '../../assets/styles/base/font.scss';
@import '../ui/helpers.scss';

.Home {
  font-family: 'Jost';
  font-weight: 500;

  .MuiFormHelperText-root {
    color: black;
    font-weight: 500;
    font-family: 'Jost';
  }

  .MuiButton-contained {
    vertical-align: middle;
    border-radius: 0.8em;
    font-weight: 500;
    text-transform: none;
    padding-left: 0.7em;
    padding-right: 0.7em;
    height: 3em;
    font-family: 'Jost';

    &.MuiButton-containedPrimary {
      background-color: #ff8743;
      color: white;
      &:hover {
        background-color: #ff8743;
        box-shadow: 0 0.25em 0 rgba(255, 135, 67, 0.32);
      }
      &.Mui-disabled {
        background-color: #ff874352;
        color: white;
      }
    }
  }

  a {
    text-decoration: none;
    color: black;
    font-size: 0.9em;
    font-weight: 400;
    text-decoration: underline;
  }

  input,
  .MuiFilledInput-root {
    background-color: #f5f5f5;
    border-radius: 0.5em;
    font-family: 'Jost';

    &:before,
    &:after {
      border-bottom: none !important;
    }

    &.table-input {
      background-color: #f9f9f9;
      border: 1px solid #ebebeb;
    }

    &:hover,
    :focus {
      background-color: #f5f5f5;
      border-color: #ebebeb;
    }

    &.Mui-disabled {
      background-color: #f9f9f9;
    }
  }

  fieldset {
    border: none;
    border-radius: 0.5em;
  }

  .sign-in-title {
    margin-top: 0.2em;
    margin-bottom: 1em;
    align-self: flex-start;
    font-size: 2em;
    font-weight: 600;
    font-family: 'Jost';
  }

  .MuiInputBase-inputAdornedEnd {
    padding: 12px 0px 8px 12px;
    height: 2em;
  }
}


.Home-link {
  color: #61dafb;
}

.Home-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-image {
  width: 2em;
  height: 2em;
}

.progress {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
