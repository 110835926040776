.segment-sidebar-card {
  margin: 2.5em;

  .sidebar-card-detail {
    font-weight: 400;
    .grey {
      color: rgba(0, 0, 0, 0.45);
    }
  }
  .icon {
    margin-top: 2em;
    border-radius: 10%;
    height: 2em;
    width: 2em;
  }
  .name {
    overflow-wrap: break-word;
  }
}