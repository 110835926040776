.image-list {
  height: 3em;
  width: 3em;
  margin-right: 1em;
}

.error {
  color: red;
}

.square-image-list {
  height: 1.5em;
  width: 1.5em;
}

.live-campaign-grid {
  max-width: 57em;
  overflow-x: auto;
}

.MuiBox-root.category-list {
  display: flex;
  flex-direction: column;
}

.MuiBox-root.category-list-headers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.category-ui {
  width: 33em;
  position: relative;
  margin-bottom: -2em;
}

.reorder-btn {
  position: absolute;
  bottom: 45px;
  left: 420px !important;
}

