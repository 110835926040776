.MuiBox-root.redemption-count {
  display: flex;
  gap: 1em;
  
}
.offer-create-picker-box {
  display: flex;
  flex-direction: row;
  background-color: #f5f5f5 !important;
  border-radius: 0.5em;
  width: 15em;
  cursor: pointer;
  height: 3.25em;
  align-items: center;
}
.offer-create-picker {
  border: none;
  background-color: #f5f5f5 !important;
  cursor: pointer;
  margin-left: 1em;
  font-family: 'Jost';
  font-size: 16px;
  width: 12em;
  outline: none;
  z-index: 2;
}
.react-datepicker-popper {
  z-index: 2 !important;
}
.offer-create {
  margin-bottom: 1.5em;
}
.MuiBox-root.create-offer-info-box {
  border: 1px solid #E8E8E8;
  border-radius: .3em;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 1.5em;
}
.MuiBox-root.flex-item {
  flex-grow: 1;
  padding-left: 1em;
  padding-top: .5em;
  padding-bottom: .5em;
}
.MuiTypography-root.title-preview {
  color: #858585;
}
.MuiBox-root.border-left {
 border-left: 1px solid #E8E8E8;
}
.MuiBox-root.border-bottom {
  border-bottom: 1px solid #E8E8E8;
 }
.MuiBox-root.form-preview-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.MuiFormControl-root{
  .description-textbox{
    background-color: #f5f5f5;
    border-radius: .5em;
  }
}
.preview-width {
  width: 4.5em ;
}
.icon-preview { 
  height: 1.5em;
  width: 1.5em;
  border-radius: .5em;
}
.thumbnail-preview { 
  height: 8em;
  width: 13em;
  border-radius: .5em;
}
.form-wrapper {
  .offer-segment-chip {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    gap: .5em;
  }
}
.offer-ui{
  width: 33em;
  position: relative;
  margin-bottom: -2em;
}
