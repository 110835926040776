.MuiSvgIcon-root.MuiStepIcon-root {
  color: #EBEBEB;
  &.MuiStepLabel-label, .MuiStepIcon-text {
    fill: black;
  }
}

.Mui-disabled {
  &.MuiStepLabel-label, .MuiStepIcon-text {
    color: #ADADAD;
    fill: #ADADAD !important;
  }
  .MuiSvgIcon-root.MuiStepIcon-root {
    color: #F5F5F5;
  }
}

.Mui-active {
  &.MuiStepLabel-label, .MuiStepIcon-text {
    fill: white !important;
  }
  
  .MuiSvgIcon-root.MuiStepIcon-root {
    color: #FF8743;
  }
}

.Mui-completed {
  .MuiSvgIcon-root.MuiStepIcon-root {
    color: #EBEBEB;
    background-color: black;
    border-radius: 50%;
  }
}

.MuiStepLabel-label, .MuiStepIcon-text {
  font-weight: 500 !important;
  color: black;
}

.MuiStepper-root {
  padding: .5em 0;
  border-bottom: 1px solid #EBEBEB;
  border-top: 1px solid #EBEBEB;
}
