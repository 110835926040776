.status-title {
  vertical-align: middle;
  &.warning {
    color: #F0AB26;
  }

  &.success {
    color: #34B677;
  }

  &.error {
    color: #F02626;
  }
}

.dot {
  height: .2em;
  width: .2em;
  border: .4em solid;
  border-radius: 50%;
  display: inline-block;
  margin-right: .5em;
  vertical-align: middle;

  &.warning {
    background-color: #F0AB26;
    border-color: #F8F5E5;
  }

  &.success {
    background-color: #34B677;
    border-color: #E5F8EF;
  }

  &.error {
    background-color: #F02626;
    border-color: #FFF4F3;
  }
}