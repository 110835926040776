.ss-date-picker-box {
  display: flex;
  flex-direction: row;
  background-color: #f5f5f5 !important;
  border-radius: 0.5em;
  width: 100%;
  height: 3.25em;
  align-items: center;
  .react-datepicker-popper {
    width: 22em;
  }
}
.ss-date-picker {
  width: 100%;
  height: 3.25em;
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 14.5px 4px 14.5px 12px !important;
  }
  .MuiInputBase-root,
  .MuiInputBase-input {
    cursor: pointer;
  }
  .MuiButtonBase-root:hover {
    background-color: unset !important;
  }
  .css-ubwxyb-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
    .MuiOutlinedInput-notchedOutline {
    background-color: unset !important;
  }
}
