.MuiList-root.sidebar {
  background: #f5f5f5;
  height: 100%;
  padding-top: 4em;
  margin-top: 0.5em;
}

.sidebar-avatar {
  height: 3.8em;
  width: 3.8em;
}

.MuiListItemButton-root {
  &.sidebar-item {
    display: inline-flex;
    color: #858585;
    text-decoration: none;
  }

  &.sidebar-item-selected {
    color: #272727;
    text-decoration: none;
  }
}

.MuiButtonBase-root {
  &.MuiListItemButton-root {
    &:hover {
      background-color: white;
    }
  }
}

.custom-svg-icon {
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.8em;
}

.sidebar-icon {
  vertical-align: middle;
  padding-right: 0.5em;
}

.sidebar-title {
  vertical-align: middle;
}

.main-content {
  padding: 3em 4em 4em 4em;
  margin-top: 1.7em;
  background-color: white;
  border-radius: 2em 0 0 0;
  width: stretch;
  min-width: fit-content;
}

.sidebar-item-collapse {
  vertical-align: middle;
  color: #858585;
  float: right;
}

.box-sidebar-item {
  padding-left: 2em;
  padding-top: 0.5em;
}

.MuiListItemButton-root.back-button-sidebar {
  padding: 0.8em 0 0.8em 1.2em;
  border-radius: 0 3em 3em 0;
  margin-right: 2em;
  background-color: #d6d6d6;
}

.sidebar-preview-image {
  width: 180px;
  height: 110px;
  border-radius: 8px;
}

.MuiBox-root.sidebar-card-detail {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.MuiSvgIcon-root.sidebar-preview-icon {
  font-size: 1em;
  color: rgba(0, 0, 0, 0.45);
  align-self: center;
  margin-right: 0.15em;
}

.MuiTypography-subtitle2.sidebar-card-text {
  margin-bottom: 0em !important;
  color: black !important;
  align-self: center;
}

.MuiTypography-subtitle2.sidebar-card-link {
  margin-bottom: 0em !important;
  color: black !important;
  text-decoration: underline;
  cursor: pointer;
  &.disabled-text {
    color: #858585 !important;
    cursor: default;
  }
}

.MuiTypography-subtitle2.options {
  color: rgba(0, 0, 0, 0.45) !important ;
  margin-bottom: 0em !important;
  align-self: center;
  margin-right: 0.15em !important;
}
