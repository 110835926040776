.marvel-device.iphone-x {
  height: 27em;
  width: 12.25em;
  border-radius: 3.3em;
  padding: 0.8em;

  &::before {
    border-radius: 3em;
  }
  .notch {
    width: 4em;
    height: 0.8em;
    top: 0.8em;
    left: 5.25em;
    &::before {
      right: 0;
    }
    &::after {
      left: 0;
    }
  }
  .volume {
    left: -2px !important;
    top: 69px !important;
    height: 22px !important;
    &::before {
      top: 2em;
      height: 1.8em;
    }
    &::after {
      top: 4.2em;
      height: 1.8em;
    }
  }
  .sleep {
    top: 69px !important;
    height: 3em !important;
  }
  .content {
    margin: 1.5em 0.7em;
  }
}

.empty-preview-box {
  display: flex;
  direction: row;

  img {
    margin-top: 0.2em;
    margin-right: 0.5em;
    border-radius: 0.5em;
    width: 6em;
    height: 3em;
  }

  .header {
    margin-top: 0.8em;
    background-color: #d9d9d9;
    border-radius: 50%;
    width: 0.7em;
    height: 0.7em;
    &::after {
      position: absolute;
      content: '';
      background-color: #e8e8e8;
      border-radius: 2em;
      width: 4em;
      margin-top: 0.1em;
      height: 0.5em;
      right: 6.1em;
    }
  }

  .empty-preview {
    margin-top: 0.2em;
    margin-right: 0.5em;
    background-color: #e8e8e8;
    border-radius: 0.5em;
    width: 6em;
    height: 3em;
    &.main {
      width: 10.5em;
      height: 7em;
      &::before {
        margin-top: 9em;
      }
    }

    &::before {
      padding-left: 0.5em;
      font-size: 0.6em;
      background-color: #d9d9d9;
      color: black;
      border-radius: 2em;
      position: absolute;
      content: '??? pts';
      width: 4em;
      margin-top: 2.5em;
      height: 1.8em;
      margin-left: 0.5em;
    }
  }

  .empty-preview-title {
    background-color: #e8e8e8;
    border-radius: 2em;
    width: 4.2em;
    margin-top: 0.5em;
    margin-left: 0.3em;
    height: 0.5em;
    margin-bottom: 0.25em;  
  }

  .empty-preview-point {
    background-color: #e8e8e8;
    border-radius: 2em;
    width: .5em;
    margin-top: 0.5em;
    height: .5em;
    margin-bottom: 0.25em;  
  }
  
  .empty-preview-offers {
    margin-top: 0.3em;
    margin-right: 0.5em;
    background-color: #e8e8e8;
    border-radius: 0.35em;
    width: 6em;
    height: 3em;
  }

  .empty-description {
    background-color: #e8e8e8;
    border-radius: 2em;
    width: 4.2em;
    margin-top: 0.8em;
    margin-left: 0.3em;
    height: 0.5em;
    right: 6.1em;
    margin-bottom: 0.5em;
    &::after {
      position: absolute;
      content: '';
      background-color: #e8e8e8;
      border-radius: 2em;
      width: 4em;
      height: 0.2em;
      width: 5.5em;
      margin-top: 0.8em;
    }
    &::before {
      position: absolute;
      content: '';
      background-color: #e8e8e8;
      border-radius: 2em;
      width: 2.2em;
      height: 0.2em;
      margin-top: -0.5em;
    }
  }

  .empty-description-offers {
    background-color: #e8e8e8;
    border-radius: 2em;
    width: 4.2em;
    margin-top: 1.25em;
    margin-left: 0.3em;
    height: 0.5em;
    right: 6.1em;
    margin-bottom: 0.5em;
    &::before {
      position: absolute;
      content: '';
      background-color: #e8e8e8;
      border-radius: 2em;
      width: 2.2em;
      height: 0.2em;
      margin-top: -0.5em;
    }
  }

  .MuiBox-root.empty-category {
    display: flex;
    flex-direction: row;
  }

  .hidden {
    visibility: hidden;
  }
}
