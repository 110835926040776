.pagination-box {
  margin-top: 0.8em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .MuiPaginationItem-root {
    color: #858585;
    &.Mui-selected {
      color: black;
      background-color: transparent;
    }
  }

  .pagination-text{
    font-weight: 400;
    font-size: 0.9em;
    color: #ADADAD;
  }

  .pagination-right {
    display: flex;
    flex-direction: row;
    gap: 2em
  }

  .per-page-select-box {
    background-color: #f9f9f9;
    border-radius: 0.5em;
    color: #858585;
    display: flex;
    flex-direction: row;
    max-height: 1.5em !important;

    .per-page-select {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      font-size: 0.9em;
      padding-top: 0;
      padding-left: .5em;
      padding-bottom: 0;
      background-color: #f9f9f9;
      color: #858585;
      align-self: start;
    }

    .MuiSelect-icon {
      color: #858585;
      font-size: 1em;
      right: 14px;
      top: 3px
    }

    .per-page-select-text {
      align-self: flex-start;
      font-size: 0.9em;
      margin-left: -0.8em;
      z-index: 2;
      padding-right: .5em;
    }
  }

  .pagination-stack {
    margin-top: -.3em;
  }
}
