// Margin right, left
.ml-0 {
  margin-left: 0 !important;
}
.ml-01 {
  margin-left: .5em !important;
}
.ml-1 {
  margin-left: 1em !important;
}
.ml-2 {
  margin-left: 2em !important;
}
.ml-3 {
  margin-left: 3em !important;
}
.ml-4 {
  margin-left: 4em !important;
}
.ml-5 {
  margin-left: 5em !important;
}
.mr-0{ 
  margin-right: 0.25em !important
}
.mr-01 {
  margin-right: .5em !important;
}
.mr-1 {
  margin-right: 1em !important;
}
.mr-2 {
  margin-right: 2em !important;
}
.mr-3 {
  margin-right: 3em !important;
}
.mr-4 {
  margin-right: 4em !important;
}
.mr-5 {
  margin-right: 5em !important;
}

// Margin top, bottom
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-01 {
  margin-bottom: 0.5em !important;
}
.mb-02 {
  margin-bottom: 0.25em !important;
}
.mb-1 {
  margin-bottom: 1em !important;
}
.mb-2 {
  margin-bottom: 2em !important;
}
.mb-3 {
  margin-bottom: 3em !important;
}
.mb-4 {
  margin-bottom: 4em !important;
}
.mb-5 {
  margin-bottom: 5em !important;
}
.mt-minus {
  margin-top: -1.25em !important;
}
.mt-minus1 {
  margin-top: -1em !important;
}
.mt-0 {
  margin-top: 0em !important;
}
.mt-01 {
  margin-top: 0.5em !important;
}
.mt-1 {
  margin-top: 1em !important;
}
.mt-2 {
  margin-top: 2em !important;
}
.mt-3 {
  margin-top: 3em !important;
}
.mt-4 {
  margin-top: 4em !important;
}
.mt-5 {
  margin-top: 5em !important;
}

// Padding top, bottom
.pb-1 {
  padding-bottom: 1em !important;
}
.pb-2 {
  padding-bottom: 2em !important;
}
.pb-3 {
  padding-bottom: 3em !important;
}
.pb-4 {
  padding-bottom: 4em !important;
}
.pb-5 {
  padding-bottom: 5em !important;
}
.pt-1 {
  padding-top: 1em !important;
}
.pt-2 {
  padding-top: 2em !important;
}
.pt-3 {
  padding-top: 3em !important;
}
.pt-4 {
  padding-top: 4em !important;
}
.pt-5 {
  padding-top: 5em !important;
}

// Padding right, left
.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: 1em !important;
}
.pl-2 {
  padding-left: 2em !important;
}
.pl-3 {
  padding-left: 3em !important;
}
.pl-4 {
  padding-left: 4em !important;
}
.pl-5 {
  padding-left: 5em !important;
}
.pr-1 {
  padding-right: 1em !important;
}
.pr-2 {
  padding-right: 2em !important;
}
.pr-3 {
  padding-right: 3em !important;
}
.pr-4 {
  padding-right: 4em !important;
}
.pr-5 {
  padding-right: 5em !important;
}

// min width
.minw-0 {
  min-width: 0em !important;
}
.minw-1 {
  min-width: 1em !important;
}
.minw-2 {
  min-width: 2em !important;
}
.minw-3 {
  min-width: 3em !important;
}
.minw-4 {
  min-width: 4em !important;
}
.minw-5 {
  min-width: 5em !important;
}
.minw-1 {
  min-width: 1em !important;
}
.minw-2 {
  min-width: 2em !important;
}
.minw-3 {
  min-width: 3em !important;
}
.minw-4 {
  min-width: 4em !important;
}
.minw-5 {
  min-width: 5em !important;
}
.minw-6 {
  min-width: 6em !important;
}
.minw-7 {
  min-width: 7em !important;
}
.minw-8 {
  min-width: 8em !important;
}
.minw-9 {
  min-width: 9em !important;
}

// max width
.maxw-1 {
  max-width: 1em !important;
}
.maxw-2 {
  max-width: 2em !important;
}
.maxw-3 {
  max-width: 3em !important;
}
.maxw-4 {
  max-width: 4em !important;
}
.maxw-5 {
  max-width: 5em !important;
}
.maxw-1 {
  max-width: 1em !important;
}
.maxw-2 {
  max-width: 2em !important;
}
.maxw-3 {
  max-width: 3em !important;
}
.maxh-4 {
  max-height: 4em !important;
}
.maxh-5 {
  max-height: 5em !important;
}
.maxh-6 {
  max-height: 6em !important;
}
.maxh-7 {
  max-height: 7em !important;
}
.maxh-8 {
  max-height: 8em !important;
}
// width
.w-100 {
  width: 100%;
}
// color
.black {
  color: black !important;
}
// display
.space-between {
  display: flex;
  justify-content: space-between;
}
.space-evenly {
  display: flex;
  justify-content: space-evenly;
}
.flex-start-alignment {
  display: flex;
  justify-content: flex-start;
}
.flex-start-self-alignment {
  display: flex;
  align-self: flex-start;
}
.flex-center-alignment {
  display: flex;
  justify-content: center;
}
.row {
  display: flex;
  flex-direction: row;
}
.column {
  display: flex;
  flex-direction: column;
}
// font
.fw-400 {
  font-weight: 400 !important;
}