.upload-image {
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: .5em;
  background-color: #f5f5f5;
  color: #858585;
  justify-content: space-between;
  padding: .75em;
  padding-left: 1em;
  
  .MuiTypography-root.upload-text {
    margin-bottom: 0em ;
    font-size: 1em;
  }
  .upload-btn{
    background-color: white ;
  }
  .square-image {
    height: 2em;
    width: 2em;
    border-radius: .5em;
    margin-bottom: 0em;
    margin-right: .5em;
  }
  .MuiBox-root.upload-preview {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-start;
  }
  .highlighted-text {
          color: #272727;
          font-weight: 400;
  }
  .MuiButtonBase-root.delete-icon {
          margin-left: auto;
        }
}
.MuiBox-root.hidden-box {
  display: none;}