.rounded-image-list {
  vertical-align: middle;
  border-radius: 50%;
  height: 2em;
  width: 2em;
  margin-right: 1em;
}
.thumbnail {
  margin-top: 2em;
  border-radius: 10%;
  height: 5em;
  width: 8.1em;
}
.createOutline {
  width: 100% !important;
}
.campaign-icon {
  margin-top: 2em;
  border-radius: 10%;
  height: 2em;
  width: 2em;
}

.shared-row-input {
  width: 100%;
}
.dialog-name {
  font-weight: 600;
}
.createCampaignWrapper,
.editCampaignWrapper {
  .preview-table {
    min-width: unset !important;
    margin-top: 10px;
    margin-bottom: 20px;
    .MuiTableCell-root {
      width: 16.66666% !important;
    }
    .MuiTableRow-root:hover {
      background-color: white !important;
    }
  }
  .preview-table-cell {
    position: relative;
    max-width: 50px !important;
    text-align: left !important;
    padding: 0 !important;
    pointer-events: none !important;
    border: 1px solid gainsboro !important;
    height: auto !important;
    .MuiTextField-root {
      width: 100% !important;
      border: none !important;
    }
    .MuiInputBase-input {
      background: white !important;
      border-radius: 0 !important;
      border: none !important;
    }
    .css-mmbrba-MuiInputBase-root-MuiFilledInput-root:before {
      border: none !important;
    }
    .css-mmbrba-MuiInputBase-root-MuiFilledInput-root:after {
      border: none !important;
    }
    .Mui-disabled {
      background-color: white !important;
    }
    .Mui-disabled:before {
      border-bottom-style: none !important;
    }
    .preview-icon-title {
      font-family: Jost;
      font-weight: 400;
      font-size: 0.75rem;
      padding-left: 6.5px;
      color: rgba(0, 0, 0, 0.38);
      margin-top: 5px;
    }
    .preview-thumbnail-title {
      font-family: Jost;
      font-weight: 400;
      font-size: 0.75rem;
      padding-left: 6.5px;
      margin-top: 10px;
      margin-bottom: 10px;
      color: rgba(0, 0, 0, 0.38);
    }
    .preview-contents-text textarea {
      -webkit-text-fill-color: black;
    }
  }
  .description {
    background-color: #f5f5f5;
    border-radius: 0.5em;
  }
  .react-datepicker-popper {
    z-index: 2 !important;
  }
  .marginTop30 {
    margin-top: 30px;
  }

  .campaignDate {
    border: none;
    background-color: #f5f5f5 !important;
    cursor: pointer;
    margin-left: 1em;
    font-family: 'Jost';
    font-size: 16px;
    width: 12em;
    outline: none;
  }
  .hidden {
    visibility: hidden;
    display: none !important;
  }
  .preview-title {
    float: left;
  }
  .preview-edit-btn {
    float: right;
    margin-top: 10px !important;
  }
  .preview-icon {
    height: 2.5em;
    width: 2.5em;
    border-radius: 0.5em;
    margin-left: 10px;
  }

  .preview-thumbnail {
    height: 8em;
    width: 13em;
    border-radius: 0.5em;
    margin-left: 10px;
  }

  .badgeWrapper {
    li {
      padding: 0px;
      margin-bottom: 10px;
    }
    .draggingListItem {
      background-color: none;
    }
    .MuiTextField-root {
      width: 9em !important;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }
    .MuiInputBase-input {
      border-radius: unset;
    }
    .singleBadge-dragIcon {
      padding-top: 0.6em;
      padding-bottom: 0.7em;
      background: #f5f5f5;
      border-top-left-radius: 0.5em;
      border-bottom-left-radius: 0.5em;
    }
    .badgeLastField {
      .MuiInputBase-input {
        border-top-right-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
      }
    }
    .removeNewBadge {
      background: none;
      border: none;
    }
    .removeBadgeIcon {
      font-size: 2em;
      color: rgba(0, 0, 0, 0.38);
    }
  }
  .badgeFieldAdded {
    .MuiInputBase-root {
      background: rgb(249 249 249) !important;
      color: black !important;
      width: 97%;
    }
    div:nth-child(3) {
      width: 19em !important;
    }
  }
  .badgeField {
    .MuiTextField-root {
      margin-right: 5px;
    }
    #singleBadgeIcon {
      height: 1px;
    }
    .singleBadgeField {
      width: 140px;
      padding: 16.5px 40px 16.5px 14px !important;
    }
    div:nth-child(2) {
      width: 19em;
    }
    .no-icon-display {
      opacity: 0.38;
    }
  }
  .addNewBadge {
    position: relative;
    border: none;
    width: 7.5em;
    height: 2.5em;
    margin-top: 1em;
    border-radius: 0.5em;
    .add-button-icon {
      position: absolute;
      top: 0.4em;
      left: 0.2em;
      font-size: 1.3em;
    }
    .add-button-text {
      position: absolute;
      top: -0.35em;
      right: 0.6em;
    }
  }
  #state-label {
    padding: 0;
  }
  .errorMsg {
    font-size: 15px;
    font-weight: 400;
    color: red;
  }
  .preview-custom-wrapper {
    min-height: 50px;
    .shrink {
      margin: 0;
      transform-origin: top left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(133% - 24px);
      transform: translate(12px, 7px) scale(0.75);
      margin-bottom: 10px;
      line-height: 1.4375em;
      position: absolute;
      min-height: 1.4em;
      top: -10px;
      left: 0px;
    }
    .preview-badge svg {
      font-size: 1em !important;
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }
  }
  .preview-custom-label {
    color: rgba(0, 0, 0, 0.38);
    font-family: Jost;
    font-weight: 400;
    font-size: 1rem;
    margin-left: 0.8em;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 14px;
  }
  .preview-custom-contents {
    padding-left: 10px;
  }
  .shrink_padding {
    padding-top: 33px;
  }
  .campaignChip {
    flex-wrap: wrap;
    flex-direction: row;
    .Mui-disabled {
      opacity: 0.7;
    }
  }
  .preview-contents-text label {
    transform: translate(12px, 7px) scale(0.75);
  }
}
.singleBadgeSelection {
  width: 200px;
}
.campaign-ui {
  width: 33em;
  position: relative;
  margin-bottom: -2em;
}
.reorder-btn {
  position: absolute;
  bottom: 45px;
  left: 420px !important;
}
