.MuiBox-root.offer-sidebar-card {
  display: flex;
  flex-direction: column;
}

.MuiTypography-subtitle1.offer-status-title {
  margin-top: 0.5em;
  vertical-align: middle;
  &.warning {
    color: #f0ab26;
  }

  &.success {
    color: #34b677;
  }

  &.danger {
    color: #f00;
  }
}

.MuiBox-root.offer-edit-btn {
  margin-left: 2em;
  margin-top: 0.3em;
}
