.rounded-image-list {
  vertical-align: middle;
  border-radius: 50%;
  height: 2em;
  width: 2em;
  margin-right: 1em;
}

.admin-access {
  width: 6em;
}

.MuiDivider-root.divider {
  margin-bottom: 1.5em;
}

.MuiDivider-root {
  margin-bottom: 1em;
}

.small-label {
  margin-top: .5em;
}

.rounded-image-details {
  margin-top: 2em;
  border-radius: 10%;
  height: 6em;
  width: 6em;
}

.square-image {
  height: 6em;
  width: 6em;
}

.search-icon {
  margin-right: .5em;
  vertical-align: middle;
}

.neutral-link {
  color: white;
  text-decoration: none;
}

.neutral-link-secondary {
  color: black;
  text-decoration: none;
}

.centered-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.error {
  color: red;
}

.title {
  margin-top: 0;
  margin-bottom: 1em;
}

.switch-label {
  max-width: 30.8em;
  text-align: justify;
}

.admin-access {
  min-width: 6em;
}

.MuiGrid-root.agencies-list-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.select-minw {
  min-width: 16em;
}

.custom-form-select {
  padding: 16.5px 32px 16.5px 14px !important;
}

.label-position {
  top: -1.5em !important
}
