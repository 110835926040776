.campaign-phone-preview {
  .featured {
    font-family: 'Lato';
    margin-left: 0.2em;
    margin-top: 0.8em;
    font-weight: 600;
    font-size: 0.6em;
    color: black;
  }

  .campaign-preview {
    display: flex;
    flex-direction: column;

    .MuiBadge-badge {
      font-size: 0.3em;
      min-width: 8.5em;
      white-space: nowrap;
      margin-left: 5em;
      background-color: #ff8743;
      color: white;
      margin-top: -3em;
    }

    img {
      margin-top: 0.3em;
      margin-right: 0.5em;
      border-radius: 0.5em;
      width: 6em;
      height: 4em;
    }

    .header {
      font-family: 'Lato';
      margin-left: 0.3em;
      font-weight: 500;
      font-size: 0.4em;
      color: #bfc7cd;
    }

    .campaign-action {
      font-family: 'Lato';
      margin-left: 0.3em;
      font-size: 0.35em;
      color: #647588;
    }

    .subheader {
      font-family: 'Lato';
      margin-left: 0.2em;
      font-weight: 600;
      font-size: 0.6em;
      color: black;
    }
  }

  .category-title {
    font-family: 'Lato';
    margin-left: 0.2em;
    margin-top: 0.5em;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 0.6em;
    color: black;
  }

  .preview-campaigns {
    display: flex;
    background-color: white;
    white-space: nowrap;
    width: 100%;
    max-height: 12em;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      border-radius: 0.5em;
      height: 7em;
      width: 100%;
    }

    .MuiBadge-badge {
      font-size: 0.6em;
      min-width: 4.5em;
      word-wrap: nowrap;
      display: flex;
      margin-left: 3em;
      margin-top: -2em;
      background-color: #ff8743;
      color: white;
    }

    .header {
      font-family: 'Lato';
      margin-left: 0.3em;
      font-weight: 500;
      font-size: 0.4em;
      color: #bfc7cd;
    }

    .campaign-action {
      font-family: 'Lato';
      margin-left: 0.3em;
      font-size: 0.35em;
      color: #647588;
    }
  }

  .subheader {
    font-family: 'Lato';
    margin-left: 0.2em;
    font-weight: 600;
    font-size: 0.6em;
    color: black;
  }

  .featured-icon {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    width: 1em;
    height: 1em;
  }
}

.black-screen {
  &::before {
    position: absolute;
    content: '';
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 10;
    margin-top: -1.5em;
    margin-left: -0.7em;
  }
}

.preview-drawer {
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  min-height: 10em;
  max-height: 15em;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .placeholder-box {
    margin-left: -4em;
  }

  .header {
    margin-top: 0.8em;
    margin-left: 0.5em;
    background-color: #e8e8e8;
    border-radius: 2em;
    width: 8em;
    height: 0.8em;
    display: list-item;
  }

  .placeholder {
    margin-top: 0.8em;
    background-color: #d9d9d9;
    border-radius: 50%;
    width: 0.7em;
    height: 0.7em;
    &::after {
      content: '';
      background-color: #e8e8e8;
      border-radius: 2em;
      width: 3em;
      margin-left: 1em;
      height: 0.5em;
      position: absolute;
    }
  }

  .name {
    font-family: 'Lato';
    margin-top: 0.4em;
    margin-left: 0.7em;
    font-weight: 600;
    font-size: 0.8em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: black;
  }

  .close {
    margin-left: 0.5em;
    margin-top: 0.5em;
    color: #858585;
  }

  .badges {
    flex-flow: wrap;
    font-weight: 400;
    margin-top: 0.5em;
    margin-left: 0.5em;
    align-items: center;
  }

  .transparent-chip {
    font-size: 0.6em;
    background-color: white;
    .MuiBox-root {
      display: flex;
    }
    .MuiChip-label {
      margin-right: 0.5em;
      padding: 0;
    }
  }

  .MuiChip-root {
    margin-bottom: 0;
  }

  .description {
    margin: .8em 1em !important;
    font-weight: 600;
    font-size: 0.5em;
    background-color: white;
    color: #858585;
    margin-bottom: 2em !important;
    max-height: 7em;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }
    ol, ul {
      padding-inline-start: 3em;
    }
  }

  .rewards-button {
    width: 94%;
    margin-right: 0.5em;
    margin-left: 0.5em;
    margin-bottom: 2em;
    height: 2em;
    font-size: 0.7em;
    background-color: black !important;
    border-radius: 0.5em;
    &:hover {
      box-shadow: 0 0.25em 0 rgba(0, 0, 0, 0.5) !important;
    }
  }
}
