.quill {
  background-color: #f5f5f5;
  color: black;
  border: none;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.ql-toolbar.ql-snow {
  border: none;
  font-family: 'Jost';
  color: black;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  background-color: hsla(0, 1%, 73%, 0.546) !important;
}

.ql-snow .ql-picker {
  font-size: 1em;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  line-height: 1.45em;
}

.ql-container.ql-snow {
  border: none;
  font-family: 'Jost';
  font-size: 1em;
  font-weight: 400;
  line-height: 1.45em;
  color: rgba(0, 0, 0, 0.87);
  min-height: 5em;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  z-index: 2;
}

.ql-snow .ql-picker-label::before {
  display: inline-block;
  line-height: 22px;
}

.ql-snow.ql-toolbar .ql-picker-label.ql-active {
  color: black;
}

.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke {
  stroke: black;
}

.ql-snow .ql-tooltip {
  z-index: 5;
  left: 0px !important;
}

.ql-snow .ql-tooltip::before {
  color: rgba(0, 0, 0, 0.87);
}

.ql-editor ul > li::before {
  font-weight: 900;
}

.ql-snow .ql-picker.ql-header {
  width: 100px;
}

.field-with-editor {
  .editor-textbox {
    width: 33em;
    background-color: #f5f5f5;
    border-radius: .5em;
  }
  .MuiBox-root.save-changes-btn-box {
    position: relative;
    background-color: #f5f5f5;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    .save-changes-btn {
      left: 67%;
      margin-bottom: 1em;
      width: 5em;
    }
    .discard-changes-btn {
      left: 66%;
      margin-bottom: 1em;
      width: 5em;
    }
    .edit-changes-btn {
        margin-bottom: 1em;
        width: 5em;
        left: 82%
    }
  }
  .text-markdown {
    font-family: 'Jost';
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.45em;
    color: rgba(0, 0, 0, 0.87);
    min-height: 79px;
    padding: 16.5px 14px;
    box-sizing: border-box;
    word-wrap: break-word;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }
}
