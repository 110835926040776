.live-offer-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 140px;
  height: 32px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.MuiButton-containedSecondary {
  &.preview-parent{
    position: relative;
  }
}

.square-image-list {
  height: 1.5em;
  width: 1.5em;
}

.live-offer-grid {
  gap: 1.25em;
  margin-left: .5em;
  max-width: 67em;
  overflow-x:auto;
  padding: 0em;

  .MuiGrid-item{
    width: 11.25em;
    height: 13.25em;

    .live-offers {
      display: flex;
      align-items: flex-start;
      padding: .4em;
      gap: .5em;
      border: 1px solid #E4E4E4;
      border-radius: .5em;
      background-color: white;
      min-width: 11em;
      min-height: 9.5em;
  
      .live-offer {
        width: 11em;
        height: 5.8em;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
      }
      .live-offer-info-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0em .5em;
        gap: .18em;
        width: 11.25em;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 1;
  
        .subheader-points{
          width: 11.25em;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #F4AD07;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }
  
        .subheader-title{
          width: 11.25em;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 13px;
          line-height: 16px;
          color: #000000;
          flex: none;
          order: 1;
          align-self: stretch;
          flex-grow: 1;
        }
      }
    }
  }

  
}

.no-live-offer-box{
  margin-top: 1em;
  margin-left: .5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: .5em;
  width: 30.37em;
  height: 8.81em;
  background: #F5F5F5;
  border-radius: 12px;

  .no-live-offer-text{
    width: 15.75em;
    height: 1.25em;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #858585;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .go-to-offers-btn{
    width: 94px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
}

.small-label {
  margin-top: .5em;
}

.live-offer-text {
  margin-left: .6em;
  margin-bottom: .1em
}

.MuiCard-root{
  &.preview-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    gap: 7.28px;
    position: absolute;
    width: 155.71px;
    height: 145.49px;
    top: 30px;
    left: calc(50% - 163.71px/2);
    background: #FFFFFF;
    box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    z-index: 1;
  }
  
  .preview-img{
    width: 155.71px;
    height: 85.49px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    .offer-img {
       width: 155.71px;
       height: 85.49px;
    }
  }
  
  .preview-info-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 7.2758px;
    gap: 2.73px;
    width: 137.71px;
    margin-right: auto;
    margin-left: auto;
    height: 40px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;

    .preview-info-points{
      width: 133.15px;
      height: 15px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 10.9137px;
      line-height: 15px;
      color: #FFAA08;
      flex: none;
      order: 0;
      align-self: stretch;
      text-align:start;
      flex-grow: 0;
    }

    .preview-info-title{
      width: 133.15px;
      height: 19px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 12.7327px;
      line-height: 14px;
      color: #000000;
      flex: none;
      order: 0;
      align-self: stretch;
      text-align:start;
      flex-grow: 1;
    }
  }
}
