.main-header {
  display: flex;
  justify-content: space-between;
  padding: .5em;
  position: sticky;
  color: #272727;
  align-items: center;
}

.user-menu-button {
  color: #272727;
  text-transform: none;
}

.MuiAvatar-circular.user-avatar {
  background-color: #FF8743;
  color: white;
}