.offer-phone-preview {
  .offer-preview {
    display: flex;
    flex-direction: column;

    img {
      margin-top: 0.3em;
      margin-right: 0.5em;
      border-radius: 0.35em;
      width: 6em;
      height: 3em;
    }

    .subheader {
      font-family: 'Lato';
      margin-left: 0.3em;
      margin-top: 0.5em;
      font-weight: 500;
      font-size: 0.4em;
      color: #ffaa08;
    }

    .header {
      font-family: 'Lato';
      margin-left: 0.2em;
      font-weight: 600;
      font-size: 0.7em;
      color: black;
    }
  }

  .category-title {
    font-family: 'Lato';
    margin-left: 0.2em;
    margin-top: 0.5em;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 0.6em;
    color: black;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }
}

.black-screen {
  &::before {
    position: absolute;
    content: '';
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 10;
    margin-top: -2em;
    margin-left: -0.7em;
  }
}

.preview-drawer {
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  min-height: 10em;
  max-height: 15em;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .name {
    font-family: 'Lato';
    margin-top: 0em;
    margin-left: 0.5em;
    font-weight: 600;
    font-size: 0.8em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: black;
  }

  .close {
    margin-left: 1em;
    margin-top: 0em;
    color: #858585;
  }

  .offer-description {
    margin: .8em 1em !important;
    font-weight: 600;
    font-size: 0.6em;
    background-color: white;
    color: #858585;
    max-height: 5em;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }
    ol, ul {
      padding-inline-start: 3em;
    }
  }

  .MuiBox-root {
    .drawer-img {
      height: 2.5em;
      width: 4em;
      margin-left: 0.5em;
      margin-top: 0.5em;
    }
  }

  .MuiBox-root.timer-box {
    display: flex;
    flex-direction: row;
    margin-left: 0.5em;
    margin-top: .25em;
    align-items: center;
    .timer-icon {
        width: .65em;
    }
    .redeem-times {
        font-size: .75em;
        margin-left: .25em;
    }
  }
  
  .preview-button {
    background-color: black;
    color: white;
    text-transform: none;
    margin-bottom: 1.5em;
    margin-left: 1em;
    width: 14em;
    height: 2.5em;
    font-size: .75em;
    margin-right:  1em;
  }

  .empty-image {
    margin-top: 0.35em;
    background-color: #e8e8e8;
    border-radius: 0.35em;
    width: 6em;
    height: 3em;
  }

  .empty-title {
    background-color: #e8e8e8;
    border-radius: 2em;
    width: 4.2em;
    margin-top: .6em;
    margin-left: 0.3em;
    height: 0.5em;
  }

  .empty-limit {
    background-color: #e8e8e8;
    border-radius: 2em;
    width: 2.2em;
    height: 0.3em;
    margin-top: .6em;
    margin-left: 0.3em;
    margin-bottom: .6em;
  }

  .empty-details {
    background-color: #e8e8e8;
    border-radius: 2em;
    width: 9.5em;
    height: 0.2em;
    margin-top: .25em;
    margin-left: 0.3em;
  }

  .empty-details-short {
    background-color: #e8e8e8;
    border-radius: 2em;
    width: 5.25em;
    height: 0.2em;
    margin-top: .25em;
    margin-left: 0.3em;
  }
}
