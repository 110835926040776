.error-box {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15em;
  align-items: center;
  gap: 1em;
  text-align: center;

  .MuiTypography-root {
    font-family: 'Jost';
  }
  .error-code {
    font-size: 150px;
    font-weight: 700;
    margin-bottom: -0.25em;
  }
  .error-title {
    font-size: 32px;
    font-weight: 700;
  }
  .error-content {
    font-size: 16px;
    font-weight: 400;
    color: #858585;
  }
  .error-btn {
    background-color: #ff8743 !important;
    text-transform: capitalize !important;

    border-radius: 12px;
    margin-top: 0.5em;
    a {
      text-decoration: none;
      color: white;
      font-family: 'Jost';
      font-size: 16px;
      font-weight: 500;
    }
  }
}
