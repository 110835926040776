.live-campaigns {
  display: flex;
  padding: .5em;
  background-color: white;
  border: 1px solid #E4E4E4;
  border-radius: .5em;
  z-index: 1;

  img {
    height: 7em;
    width: 100%;
  }

  .MuiBadge-badge {
    text-wrap: nowrap;
    min-width: 5em;
    display: flex;
    margin-left: 3em;
    margin-top: 8em;
    background-color: #FFAA08;
    color: white;
  }
  
  &.small {
    min-width: 11em;
    min-height: 12em;
    margin-top: 15em;
    margin-left: 0;
    position: absolute;
    border: none;
    text-align: start;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
    .MuiBadge-badge {
      margin-top: 6em;
    }
  }

  &.medium {
    white-space: nowrap;
    width: 11em;
    height: 12em;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .header {
    font-family: 'Lato';
    margin-left: .5em;
    margin-top: 1em;
    font-weight: 500;
    font-size: .7em;
    color: #BFC7CD;
  }

  .subheader {
    font-family: 'Lato';
    margin-top: .5em;
    margin-left: .5em;
    font-weight: 600;
    font-size: .9em;
    color: black;
  }

  .campaign-action {
    font-family: 'Lato';
    margin-top: .5em;
    margin-left: .5em;
    font-size: .7em;
    color: #647588;
  }
}