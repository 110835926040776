.segment-button {
  .MuiButton-root {
    height: 3.2em;
    margin-top: 0.1em;
  }
}

.select-autocomplete {
  width: 15em;
  text-overflow: ellipsis;
}

.MuiTableCell-root.center-cell {
  text-align: center;
  width: 6.5em;
}

.MuiBox-root.user-box-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.MuiBox-root {
  .user-box {
    background-color: #f5f5f5;
    border-radius: 0.5em;
    width: 33em;
    min-height: 9em;
    margin-top: 0.5em;
    margin-bottom: 2.5em;
  }
}

.MuiBox-root.user-box-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.MuiDivider-root.user-box-divider {
  margin-top: 1em;
  margin-bottom: -0.75em;
}

.user-dialog-msg {
  text-align: center;
  margin-top: 1em;
  height: 2em;
  p {
    margin: 5px 0 0 0 !important;
  }
}
.user-box-typo {
  font-size: 18px;
  font-weight: 700;
}

.automatic-codes {
  .MuiFormHelperText-root {
    font-size: 0.9em;
  }
}
