.stepper-divider {
  margin-top: 1em;
  margin-bottom: 1em;
}
.offer-image {
  width: 11em;
  height: 6em;
  border-radius: 0.5em;
  margin-top: 1.4em;
  margin-bottom: 0.25em;
}

.MuiBox-root.offer-date {
  flex-grow: 1;
  margin-top: 0em !important;
}

.MuiInputLabel-root.icon-label {
  margin-top: 0em !important;
}

.MuiBox-root.offer-detail-box {
  .MuiInputBase-readOnly {
    font-family: 'Jost';
  }
}

.MuiBox-root.offer-detail-step {
  justify-content: flex-start;
  align-items: flex-start;

  .error {
    color: #f02626;
  }
  .success {
    color: #34b677;
  }
}

.description-text {
  font-weight: 400;
  margin-bottom: -0.5em !important;
  margin-top: 1.25em;
}

.description-label {
  margin-top: -1em;
}

.coupon-label {
  margin-top: -1.5em;
}