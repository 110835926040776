@import '../../assets/styles/base/font.scss';
@import '../ui/helpers.scss';

.Main {
  min-height: 100vh;
  font-size: 1em;
  background-color: #f5f5f5;
  font-family: 'Jost';
  font-weight: 500;

  .MuiFormControlLabel-label {
    margin-left: 1em;
  }

  .MuiFormHelperText-root {
    color: black;
    font-size: 0.8em;
    font-weight: 500;
    margin-left: 0;
    &.Mui-error {
      color: #f02626;
      font-weight: 400;
    }
  }

  .tabs {
    margin-left: -1em;
    margin-bottom: 1em;
  }

  .MuiTab-root.Mui-selected {
    color: black;
  }

  .MuiTab-root {
    text-transform: none;
    color: #858585;
    border-bottom: 2px solid #e8e8e8;
    padding-bottom: 0;
  }

  .MuiTabs-indicator {
    background-color: black;
  }

  .MuiDivider-inset {
    margin-left: 1em;
    border-color: transparent;
  }

  input[type='number']::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
  }

  input,
  .MuiSelect-select {
    background-color: #f5f5f5;
    border-radius: 0.5em;

    &.table-input {
      background-color: #f9f9f9;
      border: 1px solid #ebebeb;
    }

    .MuiChip-root {
      height: 1.5em;
    }

    &:hover,
    :focus {
      border-color: #ebebeb;
    }
    &.Mui-disabled {
      background-color: #f9f9f9;
    }
  }

  .Mui-error {
    input,
    .MuiSelect-select {
      background-color: #fff4f3;
      border-radius: 0.5em;
      &:hover,
      :focus {
        border-color: #ebebeb;
      }
    }
  }

  .MuiInputLabel-root.small-label {
    font-size: 0.8em;
  }

  fieldset {
    border: none;
    border-radius: 0.5em;
  }

  .MuiButton-outlined {
    margin-left: 1em;
    border-color: #e8e8e8;
    text-transform: none;
    color: black;
    background-color: white;
    white-space: nowrap;
    height: 2.5em;
    border-radius: 0.5em;
    &:hover {
      border-color: #e8e8e8;
      background-color: white;
      box-shadow: 0 0.25em 0 #e8e8e8;
    }
    &.error-outlined {
      color: #f02626;
      border-color: #f02626;
      &:hover {
        border-color: #f02626;
        background-color: white;
        box-shadow: 0 0.25em 0 rgb(240, 38, 38, 0.5);
      }
    }
  }

  .MuiInputBase-readOnly {
    background-color: transparent;
    &:before {
      border-bottom: 1px solid #ebebeb;
      &:hover {
        border-bottom: 1px solid #ebebeb;
      }
    }
  }

  .MuiRadio-root.Mui-checked {
    color: #ff8743;
  }

  .MuiCircularProgress-root {
    margin-top: 2em;
    align-self: center;
    color: #ff8743;
  }

  .MuiInputLabel-standard {
    font-size: 1.1em;
  }

  .MuiFormControl-root {
    margin-left: 0;
    &.table-input {
      background-color: #f9f9f9;
      border: 1px solid #ebebeb;
    }
  }

  .MuiFormLabel-root {
    color: #adadad;

    &.select-label {
      color: #858585;
      padding: 1.5em 0.2em;
    }
    &.Mui-focused {
      color: #adadad;
    }
    &:focus {
      color: #adadad;
      border-color: #ebebeb;
    }
  }

  .MuiButton-contained {
    vertical-align: middle;
    border-radius: 0.8em;
    text-transform: none;
    padding-left: 0.7em;
    padding-right: 0.7em;
    height: 2.5em;

    &.MuiButton-containedPrimary {
      background-color: #ff8743;
      color: white;
      &:hover {
        box-shadow: 0 0.25em 0 rgba(255, 135, 67, 0.32);
      }
      &.Mui-disabled {
        background-color: #ff874352;
        color: white;
      }
    }

    &.MuiButton-containedSecondary {
      background-color: #ebebeb;
      color: black;
      &:hover {
        box-shadow: 0 0.25em 0 rgba(0, 0, 0, 0.16);
      }
      &.Mui-disabled {
        background-color: #f5f5f5;
        color: #c2c2c2;
      }
      &.live-preview {
        height: 2em;
        font-weight: 400;
        font-size: 0.9em;
        border-radius: 0.5em;
        margin-right: 0.5em;
      }
    }
  }

  .button-icon {
    vertical-align: middle;
  }

  .MuiTableContainer-root {
    overflow-x: unset;
  }

  .MuiTableRow-root {
    border-top: 1px solid white;
    &:hover {
      background-color: #f9f9f9;
      border-bottom: 1px solid #e8e8e8;
      border-top: 1px solid #e8e8e8;
      &:last-child {
        border-bottom: none;
      }
    }
  }

  .MuiTableCell-root {
    a {
      text-decoration: none;
      color: black;
      &:hover {
        text-decoration: underline;
      }
    }

    &.header-cell {
      padding: 1em;
      line-height: 0.5em;
      color: #adadad;
      border-right: 1px solid #e8e8e8;
      border-top: 1px solid #e8e8e8;
    }

    &.centered-cell {
      text-align: center;
    }

    &.body-cell {
      &.centered-cell {
        width: 5em;
        text-align: center;
      }
      border-bottom: none;
    }

    &.last-header-cell {
      padding: 1em;
      line-height: 0.5em;
      color: #adadad;
      border-right: none;
      border-top: 1px solid #e8e8e8;
    }

    &.second-last-header-cell {
      padding: 1em;
      line-height: 0.5em;
      color: #adadad;
      border-right: none;
      border-top: 1px solid #e8e8e8;
    }
  }

  .MuiTypography-h5 {
    margin: 0.5em 0;
    font-size: 1.2em;
    font-weight: 700;
  }

  .MuiTypography-subtitle2 {
    font-size: 0.9em;
    color: #858585;
    font-weight: 400;
    margin-bottom: 0.5em;
  }

  .form-control {
    margin: 0.5em 0.5em 0 0;
    min-width: 120px;
  }

  .subtitle3 {
    color: #858585;
    font-weight: 400;
    margin-bottom: 0.5em;
  }

  .MuiTypography-subtitle1 {
    font-size: 0.9em;
    font-weight: 500;
  }

  .mini-subtitle {
    font-size: 0.8em;
    color: #adadad;
  }

  .MuiSvgIcon-colorSuccess {
    color: #34b677;
  }

  .MuiSvgIcon-colorWarning {
    color: #f0ab26;
  }

  .MuiSvgIcon-colorError,
  .MuiIconButton-colorError {
    color: #f02626;
  }

  .list-actions {
    svg {
      margin-right: 0.5em;
      color: #858585;
    }
    visibility: hidden;
  }

  .flex-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .row-with-actions {
    &:hover {
      .list-actions {
        visibility: visible;
      }
    }
  }

  .MuiBadge-badge {
    background-color: white;
    color: #858585;
    width: 3em;
  }

  .MuiChip-root {
    height: 1.8em;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
    font-weight: 400;
    &.clickable {
      cursor: pointer;
    }
    svg {
      font-size: 1.25em;
    }
  }

  .warning {
    color: #f0ab26;
  }

  .success {
    color: #34b677;
  }

  .error {
    color: #f02626;
  }

  .black {
    color: black;
  }
  .info {
    color: #adadad;
  }

  .details-box {
    margin-left: 0.5em;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .MuiBox-root.details-date-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1em;
  }

  a {
    text-decoration: none;
    &:visited {
      text-decoration: none;
    }
  }

  .react-datepicker__triangle {
    background-color: white;
  }

  .react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
  }

  .react-datepicker__header {
    background-color: white;
    border-bottom: none;
  }

  .react-datepicker__day-names {
    background-color: #f5f5f5;
    border: none;
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 1em;
  }

  .react-datepicker__day.react-datepicker__day--keyboard-selected,
  .react-datepicker__day.react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    border: none;
    border-radius: 10%;
    background-color: #ff8743;
    color: white;
    font-weight: 400;
  }

  .react-datepicker__day {
    &:hover {
      background-color: #f5f5f5;
      color: black;
    }
  }

  .react-datepicker__close-icon::after {
    background-color: #adadad;
  }

  .react-datepicker__day--outside-month {
    visibility: hidden;
  }

  .react-datepicker__current-month {
    font-weight: 500;
  }

  .react-datepicker__day {
    font-weight: 400;
  }

  .detail-card-box {
    display: flex;
    flex-direction: column;
    margin: 3em 3em;
  }

  .details-section-box {
    margin-left: 0.5em;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .box-details {
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 0.5em;
  }

  .space-between {
    justify-content: space-between;
  }
}

.MuiCheckbox-root.MuiButtonBase-root {
  color: #858585 !important;
  &.Mui-checked {
    color: #ff8743 !important;
  }
}

.multi-select-item {
  &.Mui-selected {
    background-color: white !important;
  }
}
