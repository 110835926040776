@font-face {
  font-family: Jost;
  font-weight: 900;
  src: url('../../font/jost/Jost-Black.ttf') format("opentype");
}
@font-face {
  font-family: Jost;
  font-weight: 800;
  src: url('../../font/jost/Jost-ExtraBold.ttf') format("opentype");
}
@font-face {
  font-family: Jost;
  font-weight: 700;
  src: url('../../font/jost/Jost-Bold.ttf') format("opentype");
}
@font-face {
  font-family: Jost;
  font-weight: 600;
  src: url('../../font/jost/Jost-SemiBold.ttf') format("opentype");
}
@font-face {
  font-family: Jost;
  font-weight: 500;
  src: url('../../font/jost/Jost-Medium.ttf') format("opentype");
}
@font-face {
  font-family: Jost;
  font-weight: 400;
  src: url('../../font/jost/Jost-Regular.ttf') format("opentype");
}
@font-face {
  font-family: Jost;
  font-weight: 300;
  src: url('../../font/jost/Jost-Light.ttf') format("opentype");
}
@font-face {
  font-family: Jost;
  font-weight: 200;
  src: url('../../font/jost/Jost-Thin.ttf') format("opentype");
}
@font-face {
  font-family: Jost;
  font-weight: 100;
  src: url('../../font/jost/Jost-Thin.ttf') format("opentype");
}
@font-face {
  font-family: Lato;
  font-weight: 100;
  src: url('../../font/lato/Lato-Regular.ttf') format("opentype");
}