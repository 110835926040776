.drop-zone {
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: .5em;
  background-color: #F9F9F9;
  color: #858585;
  transition: border .24s ease-in-out;
  padding: 1.5em;
  background-image: linear-gradient(to right, #ADADAD 50%, transparent 50%), linear-gradient(to right, #ADADAD 50%, transparent 50%), linear-gradient(to bottom, #ADADAD 50%, transparent 50%), linear-gradient(to bottom, #ADADAD 50%, transparent 50%);
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 17px 1px, 17px 1px, 1px 17px, 1px 17px;
}

.highlighted-text{
  color: #272727;
  margin-bottom: .5em;
  font-weight: 400;
}

.MuiTypography-root.card-text{
  color: #858585;
  font-size: .8em;
  font-weight: 400;
}

.dropzone-card {
  border-radius: .5em;
  background-color: #F5F5F5;
  padding: .5em .5em .5em .5em;
}

.square-image {
  height: 5em;
  width: 5em;
  border-radius: .5em;
  margin-bottom: .5em;
}

.MuiButtonBase-root.delete-icon {
  margin-left: auto;
}